import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { navigate, Link } from 'gatsby'
import { useUserContext } from '~providers/user'
import scssVars from '~components/scss-variables'
import SEO from '~components/seo'
import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import HeroSection from '~components/layouts/marketing/HeroSection'

import Form from '~components/DeliveryForm'

const StyledContainer = styled(Container)`
  max-width: 1280px !important;
`

const GoldBox = styled.div`
  display: inline-block;
  padding: 20px 25px;
  border: 2px solid ${scssVars.orange};
  h2 {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p strong {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }
  ol {
    padding-left: 15px;
  }
  li {
    font-size: 16px;
    padding-bottom: 10px;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding: 30px;
  }
`

const DeliveryServiceCopy = () => (
  <>
    <h2>We offer delivery services to most of Manhattan, much of Brooklyn, and Long Island City, Queens. Where we can't deliver, we ship via UPS!</h2>
    <p>The local delivery service fee is $14-18 for Manhattan, $18 for Brooklyn and LIC.</p>
    <p>Where we can’t deliver, we ship via UPS Ground Service. Flat rate of $18 within the continental United States.</p>
    <p>Local deliveries and UPS shipments are prepared within one to two business, no same-day outgoing service.</p>
    <p>Local deliveries are made Monday - Friday, 10am - 8pm for residential addresses and 10am - 5pm for commercial addresses.</p>
    <p>Someone must be present at your local delivery address to accept and sign for your item(s), otherwise, items will not be delivered and will be rescheduled for the next available date; a re-delivery fee will apply. All deliveries require a signature. If delivery is for a commercial address, please include your company name and floor or suite.</p>
    <p>Please make sure to read our <a className="text-link" href="/operational-policies" target="_blank">shipping and delivery policies</a>.</p>
  </>
)

const RightColumn = () => (
  <section className="py-0 pt-md-3 py-lg-5">
    <StyledContainer>
      <Row>
        <Col className="d-flex justify-content-center">
          <GoldBox>
            <div className="align-self-center">
              <DeliveryServiceCopy />
            </div>
          </GoldBox>
        </Col>
      </Row>
    </StyledContainer>
  </section>
)

const DeliveryServicePage = () => {
  const { isAuthenticated } = useUserContext()
  const [formActive, toggleForm] = useState(false)
  return (
    <Layout>
      <SEO title="Delivery Service" pathname="/customer-services/delivery" />
      <Main className="mb-5">
        <HeroSection title={'Delivery Service'} fluid={false}
          breadcrumb={{ title: 'At Your Service', path: '/customer-services' }}
          mobileImg="https://leatherspa.imgix.net/Customer-Services/Individual-Pages/LS_Delivery-Service_Image.jpg?fit=scale&w=1360"
          desktopImg="https://leatherspa.imgix.net/Customer-Services/Individual-Pages/LS_Delivery-Service_Image.jpg?fit=scale&w=992"
          rightColumnChildren={formActive && <RightColumn />}
        >
          {formActive
            ? <Form toggleForm={toggleForm} />
            : (
              <>
                <DeliveryServiceCopy />
                <button className="w-auto px-5 mt-4 btn btn-primary" onClick={() => isAuthenticated() ? toggleForm(true) : navigate('/log-in')}>
                  {isAuthenticated() ? 'Request A Delivery' : 'Log In To Request A Delivery'}
                </button>
              </>
              )}
        </HeroSection>
      </Main>
    </Layout>
  )
}

export default DeliveryServicePage
