import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { Formik } from 'formik'
import SquarePaymentForm from 'react-square-payment-form'
import { Link } from 'gatsby'

import { ModalContext } from '~providers/ModalContext'
import LoadingContent from '~components/Modals/Loading'
import Modal from '~components/Shared/Modal'
import { ReusableModal } from '~components/Modals/Modal'
import scssVars from '~components/scss-variables'

import { CREATE_DELIVERY } from '~mutations/tickets'

import env from '../../env'
import InnerForm from './Form'

const LoadingModal = styled(ReusableModal)`
  width: 100px !important;
  .modal-content {
    box-shadow: none;
    background: none;
    border: none;
  }
`

const DeliveryForm = () => {
  const [createDelivery, { data = {}, loading, errors: apiErrors }] = useMutation(CREATE_DELIVERY)
  const [unrecoverableError, setUnrecoverableError] = useState(null)
  const [ccErrors, setCCErrors] = useState([])
  const { modalOpen, toggleModal } = useContext(ModalContext)

  const validate = (values) => {
    const errors = {}
    if (!(values.ticketId || values.externalId)) {
      errors.ticketId = 'Please specify a repair order.'
    }
    if (isNaN(values.subtotal) || values.subtotal < 0) {
      errors.subtotal = 'There was an error calculating your subtotal. Please refresh the page or contact support for help.'
    }
    if (values.shippingFee < 14) {
      errors.shippingFee = 'There was an error calculating your shipping fee. Please refresh the page or contact support for help.'
    }
    if (!(values.returnAddressId || values.returnAddress?.postalCode)) {
      errors.returnAddress = 'Please provide a return address'
    }
    return errors
  }

  const createVerificationDetails = (amount) => {
    return {
      amount: amount.toFixed(2).toString(),
      currencyCode: 'USD',
      intent: 'CHARGE',
      billingContact: {}
    }
  }

  const cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken, { errors: formikErrors, values, setFieldValue, setSubmitting }) => {
    const filteredErrors = errors ? errors.filter(e => ![undefined, null, ''].includes(e)) : []
    if (filteredErrors.length > 0) {
      setCCErrors(errors.filter(e => ![undefined, null, ''].includes(e)) || [])
    } else if (Object.keys(formikErrors).length > 0) {
      // do nothing
    } else {
      setCCErrors([])
      try {
        values.payment.nonce = nonce
        await createDelivery({ variables: values })
      } catch (e) {
        Sentry.captureException(e)
        setUnrecoverableError(true)
      }
    }
    setSubmitting(false)
    toggleModal(false)
  }

  const initialValues = {
    payment: {
      nonce: '',
      amountCents: 1800,
    },
    ticketId: '',
    externalId: '',
    subtotal: 0,
    shippingFee: 18,
    returnAddressId: undefined,
    returnAddress: undefined
  }

  if (unrecoverableError) {
    return <Modal
      title="An error occured"
      body="There was a problem processing your request. Please try again later."
      hideCloseButton={true}
    />
  } if (!loading && data.createDelivery?.ticket) {
    return (
      <div>
        <h4 className="text-center" style={{ color: scssVars.brightOrange }}>Payment was Successful!</h4>
        <p className="text-center">You will receive an email shortly confirming your payment.</p>
        <Link to={`/account/tickets/${data.createDelivery.ticket.id}`} className="btn btn-primary w-100">Review Ticket Details</Link>
      </div>
    )
  } else {
    return (
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={() => toggleModal(true)}
      >
        {(formikInstance) => (
          <SquarePaymentForm
            sandbox={env.SQUARE_SANDBOX}
            applicationId={env.SQUARE_APPLICATION_ID}
            locationId={env.SQUARE_LOCATION_ID}
            createVerificationDetails={() => createVerificationDetails(formikInstance.values.payment.amountCents / 100)}
            cardNonceResponseReceived={( errors, nonce, cardData, token) => {
              return cardNonceResponseReceived(errors, nonce, cardData, token, formikInstance)
            }
          }>
            <LoadingModal
              isOpen={modalOpen}
              toggleModal={toggleModal}
              showCloseButton={false}
              backdropClassName='modal-backdrop bg-clr-white'
            >
              <LoadingContent />
            </LoadingModal>
            <InnerForm
              ccErrors={ccErrors}
              setCCErrors={setCCErrors}
            />
          </SquarePaymentForm>
        )}
      </Formik>
    )
  }
}

export default DeliveryForm
