import React, { useContext, useEffect, useState } from 'react'
import SquareContext from 'react-square-payment-form/lib/components/Context'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Row, Col } from 'reactstrap'
import { useQuery } from '@apollo/client'
import { Form as FormikForm, useFormikContext, ErrorMessage } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'
import UsedAddressesDropdown from '~components/EstimatesDelivery/Shared/UsedAddressesDropdown'
import { SectionTitle } from '~components/Services/Payment/Components'
import AddressForm from '~components/EstimatesDelivery/Shared/NewAddressForm'
import CC from '~components/EstimatesDelivery/Shared/CC'
import { Radio, Field } from '~components/Services/Payment/Components'
import { InlineError } from '~components/EstimatesDelivery/Shared/Components'

import { getPostalCodes } from '~queries/postalCode'
import { USER_TICKETS_QUERY } from '~queries/tickets'

const Label = styled.label`
  display: block;
  font-family: 'Montserrat', sans-serif;
  span {
    margin-left: 5px;
    vertical-align: top;
  }
`

const StyledSectionTitle = styled(SectionTitle)`
  /* OVERRIDE Content h2 styling */
  font-size: 24px !important;
`

const TotalRow = styled(Row)`
  color: ${scssVars.orange};
  margin-top: 5px;
  font-size: 1.25em;
  font-weight: 600;
`

const BreakdownWrapper = styled.div`
  font-family: Montserrat, sans-serif;
`

const FormikField = styled(Field)`
  font-family: 'Montserrat', sans-serif;
  padding: 0 0 5px 10px;
  width: 100%;
`

const CheckmarkIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheck
})`
  color: ${scssVars.brightOrange};
  margin: 0 5px 1px 0;
`

const RadioGroup = styled.div`
  input {
    margin-right: 5px;
  }
  label {
    bottom: -2px;
  }
`

export default function Form ({ ccErrors }) {
  const { data: { myRepairTickets = [] } = {} } = useQuery(USER_TICKETS_QUERY)
  const squareContext = useContext(SquareContext)
  const { values, isSubmitting, isValid, setFieldValue } = useFormikContext()
  const [isLocal, setLocal] = useState(false)
  const [deliveryFee, setDeliveryFee] = useState(0)
  const postalCodes = getPostalCodes()

  const onPostalCodeChange = (postalCode) => {
    const localDeliveryZone = postalCodes.find(p => p.id === postalCode)
    if (localDeliveryZone) {
      setDeliveryFee(localDeliveryZone.fees[0])
      setLocal(true)
    } else {
      setDeliveryFee(18)
      setLocal(false)
    }
  }

  const tickets = myRepairTickets.filter(t => t.returnMethod === 'drop_off' && t.status === 'pending_customer_receipt')

  const ticket = tickets.find(t => t.id === values.ticketId)
  const subtotal = ticket ? ticket.amountDue : values.subtotal

  useEffect(_ => {
    if (values.returnAddressId) delete values.returnAddress
  }, [values.returnAddressId])

  useEffect(_ => {
    onPostalCodeChange(values.returnAddress?.postalCode)
  }, [values.returnAddress?.postalCode])

  useEffect(_ => {
    setFieldValue('shippingFee', deliveryFee)
    setFieldValue('payment.amountCents', (subtotal + deliveryFee) * 100)
  }, [subtotal, deliveryFee])

  return (
    <FormikForm>
      <section className="mt-3 mb-5">
        <StyledSectionTitle>1. Delivery Address</StyledSectionTitle>
        <UsedAddressesDropdown fieldPrefix="returnAddress" formType="delivery" postalCodes={postalCodes} addressCallback={a => onPostalCodeChange(a?.postalCode)} />
        {!values.returnAddressId && <AddressForm fieldPrefix="returnAddress" />}
      </section>
      <section>
        <StyledSectionTitle>2. Repair Order</StyledSectionTitle>
        <div className="mt-3 mb-4">
          <RadioGroup className="mb-4">
            {tickets.map(ticket => (
              <Label className="mt-0" htmlFor={ticket.id} key={ticket.id}>
                <Radio type="radio" name="ticketId" className="mb-0 mr-1" id={ticket.id} value={ticket.id} />
                <span className="align-top">
                  <span className="text-uppercase">{ticket.confirmationCode} ({ticket.status})</span>
                  <span className="float-right">${ticket.amountDue.toFixed(2)}</span>
                </span>
              </Label>
            ))}
            <Label className="mt-0 d-flex" id="other">
              <Radio type="radio" name="ticketId" className="mb-0 mr-1" id="other" checked={!values.ticketId} value={undefined} />
              <span className="align-top">Other (please specify ticket number and balance due)</span>
            </Label>
            {!values.ticketId && (
              <Row className="mt-3">
                <Col>
                  <FormikField name="externalId" placeholder="Ticket Number" required />
                  <ErrorMessage name="externalId" />
                </Col>
                <Col>
                  <FormikField name="subtotal" placeholder="Ticket Price" type="number" min="0" step="0.01" required />
                  <ErrorMessage name="subtotal" />
                </Col>
              </Row>
            )}
          </RadioGroup>
        </div>
        <BreakdownWrapper>
          <Row>
            <Col className="col-8">Subtotal</Col>
            <Col className="text-right">${(subtotal || 0).toFixed(2)}</Col>
          </Row>
          <Row>
            <Col className="col-8">{isLocal ? 'White Glove ' :  'UPS '}Return Delivery</Col>
            <Col className="text-right">{deliveryFee ? `$${deliveryFee.toFixed(2)}` : '?'}</Col>
          </Row>
          <TotalRow>
            <Col className="col-8">Total Due</Col>
            <Col className="text-right">${(values.payment.amountCents / 100).toFixed(2)}</Col>
          </TotalRow>
        </BreakdownWrapper>
      </section>
      <hr className="my-5" />
      <section>
        <StyledSectionTitle>3. Payment Information</StyledSectionTitle>
        <CC errors={ccErrors} />
      </section>
      <hr />
      <section className="mb-3">
        <Label className="w-100">
          <CheckmarkIcon />
          <span>I have read and accept Leather Spa&apos;s <a href="/operational-policies" target="_blank">delivery policies</a>.</span>
        </Label>
        <Label className="w-100">
          <CheckmarkIcon />
          <span>I agree that Leather Spa is not liable for any loss or damage to items once they have been signed over.</span>
        </Label>
      </section>
      <button type="submit" className="btn btn-primary w-100" disabled={!isValid || isSubmitting} onClick={squareContext.onCreateNonce}>
        Submit &amp; Pay
      </button>
    </FormikForm>
  )
}
