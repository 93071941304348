import gql from 'graphql-tag'

export const USER_TICKETS_QUERY = gql`
  {
    myRepairTickets {
      id
      price
      subtotal
      shippingFee
      amountPaid
      amountDue
      isRush
      type
      returnMethod
      confirmationCode
      status
      formattedPrice
      createdAt
      returnAddress {
        addressLine2
        city
        id
        name
        postalCode
        phoneNumber
        state
        stateCode
        street
        isResidential
        hasDoorman
      }
      items {
        id
        confirmationCode
        color
        formattedPrice
        estimatedPrice
        brand
        material
        description
        additionalInformation
        apparel {
          id
          name
        }
        isRush
        options {
          id
          name
          formattedPrice
          category {
            id
            name
          }
        }
      }
    }
  }
`;

export const SINGLE_TICKET_QUERY = gql`
  query RepairTicket($id: String!) {
    myRepairTicket(id: $id) {
      id
      price
      subtotal
      shippingFee
      amountPaid
      amountDue
      isRush
      type
      confirmationCode
      status
      formattedPrice
      createdAt
      advisorNote
      returnMethod
      returnAddress {
        addressLine2
        city
        id
        name
        postalCode
        phoneNumber
        state
        stateCode
        street
        isResidential
        hasDoorman
      }
      items {
        id
        confirmationCode
        color
        formattedPrice
        estimatedPrice
        brand
        material
        description
        photoUrl
        additionalInformation
        advisorNote
        apparel {
          id
          name
        }
        isRush
        options {
          id
          name
          formattedPrice
          category {
            id
            name
          }
        }
      }
    }
  }
`;
